import { client as webauthnClient } from '@passwordless-id/webauthn'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateCurrentUser } from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { ModalEditPassword } from 'components/Modal/EditPassword'
import { ModalEditPersonalInfo } from 'components/Modal/EditPersonalInfo'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { rootDomain } from 'constants/domain'
import { useSession } from 'hooks/use-session'
import {
  getRegistrationChallenge,
  storeRegistration,
} from 'services/api/session'
import { message } from 'utils/message'
import { formatPhone } from 'utils/phone'

const connections = [
  {
    id: 'microsoftoutlook',
    name: 'Outlook',
    icon: 'microsoftoutlook.png',
  },
  {
    id: 'googlemail',
    name: 'Gmail',
    icon: 'googlemail.svg',
  },
]

function MyInfo() {
  const { user, refreshUser } = useSession()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateCurrentUser()

  const [searchParams, setSearchParams] = useSearchParams()

  const show2FA = user?.client.settings?.mfa === true

  async function handle2FASetup() {
    const { challenge } = await getRegistrationChallenge({
      client: user?.client.id,
    })

    const registration = await webauthnClient.register({
      user: {
        id: user?.admin?.email as string,
        name: user?.admin?.email as string,
      },
      challenge,
    })

    await storeRegistration({
      subdomain: user?.client.subdomain as string,
      registration,
    })

    refreshUser()
  }

  async function disablePasskey() {
    update({ credentials: null }, { onSuccess: () => {} })
  }

  useEffect(() => {
    const emailConnectionId = searchParams.get('id')
    const emailConnectionType = searchParams.get('type')

    if (emailConnectionId) {
      update(
        {
          emailConnectionId,
          emailConnectionType,
        },
        {
          onSuccess: () => {
            setSearchParams()
          },
        }
      )
    }
  }, [searchParams, setSearchParams])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="My Info"
        />

        <Panel
          title="Personal Information"
          onEdit={() => setIsEditModalVisible(true)}
          className="max-w-panel"
        >
          <Grid gap={16}>
            <Grid.Item xs={12}>
              <Summary name="Name">{user?.admin?.name}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Email">{user?.admin?.email}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Phone number">
                {formatPhone(user?.admin?.phone)}
              </Summary>
            </Grid.Item>
          </Grid>
        </Panel>
        <Panel
          title="Security"
          onEdit={() => setIsPasswordModalVisible(true)}
          className="max-w-panel"
        >
          <Grid gap={16}>
            <Grid.Item xs={12}>
              <Summary name="Password">••••••••</Summary>
            </Grid.Item>
            {show2FA && (
              <Grid.Item xs={12}>
                <Summary name="Multi-Factor Authentication with Passkey">
                  {user?.admin?.login.numCredentials ? (
                    <>
                      <Badge color="green">Active</Badge>
                      <Button
                        size="small"
                        color="negative"
                        variant="secondary"
                        className="ml-2"
                        onClick={disablePasskey}
                      >
                        Disable Passkey
                      </Button>
                    </>
                  ) : (
                    <Button onClick={handle2FASetup}>Enable</Button>
                  )}
                </Summary>
              </Grid.Item>
            )}
          </Grid>
        </Panel>
        <Panel title="Email **IN DEVELOPMENT**" className="max-w-panel">
          <Flex stack gap={16}>
            <Flex gap={16}>
              <Summary name="Mail Forwarding Address">
                <a
                  className="link"
                  href={`mailto:admin+${user?.admin?.id}@reply${rootDomain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  admin+{user?.admin?.id}@reply{rootDomain}
                </a>
              </Summary>
              <CopyToClipboard
                text={`admin+${user?.admin?.id}@reply${rootDomain}`}
                onCopy={() => message.success('Link copied')}
              >
                <Button size="small">Copy URL</Button>
              </CopyToClipboard>
            </Flex>
            <Summary name="Direct Connection">
              <Flex gap={16}>
                {!user?.admin?.emailConnectionType &&
                  connections.map((connection) => (
                    <Button
                      key={connection.id}
                      variant="secondary"
                      size="large"
                      onClick={() => {
                        document.location = `https://api.unified.to/unified/integration/auth/6670ecd29da376c87a94a653/${connection.id}?redirect=1&external_xref=${
                          user?.admin?.id
                        }&scopes=messaging_message_read%2Cmessaging_message_write%2Cmessaging_channel_read%2C&env=dev&success_redirect=${encodeURIComponent(window.location.href)}&failure_redirect${encodeURIComponent(window.location.href)}`
                      }}
                    >
                      <img
                        src={`https://api.unified.to/docs/images/${connection.icon}`}
                        alt="Outlook"
                        style={{ height: '20px', width: '20px' }}
                      />
                      {connection.name}
                    </Button>
                  ))}
                {user?.admin?.emailConnectionType && <>You are connected</>}
              </Flex>
            </Summary>
          </Flex>
        </Panel>
      </Flex>
      {isEditModalVisible && (
        <ModalEditPersonalInfo
          initialValues={{
            email: user?.admin?.email as string,
            name: user?.admin?.name as string,
            phone: user?.admin?.phone || '',
          }}
          saving={isUpdating}
          onClose={() => setIsEditModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsEditModalVisible(false) })
          }
        />
      )}
      {isPasswordModalVisible && (
        <ModalEditPassword
          saving={isUpdating}
          onClose={() => setIsPasswordModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsPasswordModalVisible(false) })
          }
        />
      )}
    </MainContent>
  )
}

export { MyInfo }
