import { without } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useThreads, useUpdateThreads } from 'admin/hooks/use-threads'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { TableStickyFooter } from 'components/TableStickyFooter'
import { usePagination } from 'hooks/use-pagination'
import {
  IThreadCheckboxes,
  IThreadListItem,
  IThreadRequest,
  IThreadTab,
} from 'types'
import { Empty } from './Empty'
import { ThreadsTable } from './ThreadsTable'

interface Props {
  tab: IThreadTab
  search?: string
  loanId?: string
  onRowClick: (row: IThreadListItem | undefined) => void
  checkboxes: IThreadCheckboxes
  onCheckboxesChange: (data: IThreadCheckboxes) => void
}

function ThreadsTab({
  tab,
  search,
  loanId,
  onRowClick,
  checkboxes,
  onCheckboxesChange,
}: Props) {
  const { mutate: updateThreads } = useUpdateThreads()
  const { visibleItems, result, isEmpty, setPagination } =
    usePagination<IThreadListItem>({
      property: 'threads',
      search,
      useData: (params) =>
        useThreads({ ...params, folder: tab, loanId, checkMail: true }),
    })
  const { data: selectedResult } = useThreads({
    pagination: { page: 0, size: 1 },
    search,
    folder: tab,
    loanId,
    filter: ['read', 'unread'].includes(checkboxes.mode!)
      ? { isRead: [checkboxes.mode === 'read'] }
      : undefined,
  })

  const selectedCount = useMemo(() => {
    if (checkboxes.mode) {
      return (
        (selectedResult?.meta?.total || 0) -
        checkboxes.excludeIds.length +
        checkboxes.includeIds.length
      )
    }
    return checkboxes.includeIds.length
  }, [checkboxes, selectedResult])

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      if (checkboxes.includeIds.includes(id)) {
        onCheckboxesChange({
          ...checkboxes,
          includeIds: without(checkboxes.includeIds, id),
        })
      } else if (checkboxes.excludeIds.includes(id)) {
        onCheckboxesChange({
          ...checkboxes,
          excludeIds: without(checkboxes.excludeIds, id),
        })
      } else if (checkboxes.mode && !checked) {
        onCheckboxesChange({
          ...checkboxes,
          excludeIds: [...checkboxes.excludeIds, id],
        })
      } else {
        onCheckboxesChange({
          ...checkboxes,
          includeIds: [...checkboxes.includeIds, id],
        })
      }
    },
    [checkboxes]
  )

  const toggleThreads = useCallback(
    (payload: IThreadRequest) => {
      updateThreads({
        folder: tab,
        search,
        filter: ['read', 'unread'].includes(checkboxes.mode!)
          ? { isRead: [checkboxes.mode === 'read'] }
          : undefined,
        include: !checkboxes.mode ? checkboxes.includeIds : [],
        exclude: checkboxes.mode === 'all' ? checkboxes.excludeIds : [],
        payload,
      })
    },
    [updateThreads, tab, search, checkboxes]
  )

  const someUnreadSelected = useMemo(() => {
    if (checkboxes.mode) {
      return true
    }
    return visibleItems.some(
      (item) => checkboxes.includeIds.includes(item.id) && !item.isRead
    )
  }, [checkboxes, visibleItems])

  useEffect(() => {
    if ((result.data?.meta?.page || 0) === 0) {
      onCheckboxesChange({ mode: null, includeIds: [], excludeIds: [] })
    }
  }, [onCheckboxesChange, result.data])

  return (
    <>
      {isEmpty ? (
        <Empty tab={tab} loanMode={!!loanId} />
      ) : (
        <div className="pb-4">
          <ThreadsTable
            key={tab}
            data={visibleItems}
            loading={result.isLoading}
            onCheck={handleCheckboxChange}
            checkboxes={checkboxes}
            onRowClick={onRowClick}
          />
          <LoadMore
            loading={result.isPending}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
            fetching={result.isFetching}
          />
        </div>
      )}
      {selectedCount > 0 && (
        <TableStickyFooter className="-mx-8">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="w-full relative"
          >
            <div className="absolute left-3">{selectedCount} Selected</div>
            <Flex alignItems="center" justifyContent="center" gap={10}>
              {tab !== 'trash' && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      toggleThreads({ isRead: someUnreadSelected })
                    }
                  >
                    <Icon
                      name={IconName.markAsUnread}
                      className="mr-1 text-grey-600"
                    />
                    Mark as {someUnreadSelected ? 'Read' : 'Unread'}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      toggleThreads({ isArchived: tab !== 'archived' })
                    }
                  >
                    <Icon
                      name={IconName.archive}
                      className="mr-1 text-grey-600"
                    />
                    {tab === 'archived' ? 'Unarchive' : 'Archive'}
                  </Button>
                </>
              )}
              <Button
                variant="secondary"
                onClick={() => toggleThreads({ isTrash: tab !== 'trash' })}
              >
                <Icon name={IconName.delete} className="mr-1 text-grey-600" />
                {tab === 'trash' ? 'Undelete' : 'Delete'}
              </Button>
            </Flex>
          </Flex>
        </TableStickyFooter>
      )}
    </>
  )
}

export { ThreadsTab }
