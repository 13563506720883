import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getThreadMail } from 'admin/services/api/threads'
import { KEY_THREADS } from 'constants/query-keys'
import { useWebSocket } from 'hooks/use-websocket'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { IMail } from 'types'
import { message } from 'utils/message'
import {
  addMailAttachment,
  removeMailAttachment,
} from '../services/api/thread-attachments'

const useAddMailAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addMailAttachment,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveMailAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeMailAttachment,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useUploadAttachment = ({
  mail,
  onUpload,
}: {
  mail: IMail
  onUpload?: () => void
}) => {
  const queryClient = useQueryClient()
  const { lastMessage } = useWebSocket()
  const [uploadToast, setUploadToast] = useState<any>()

  useEffect(() => {
    if (lastMessage?.type === 'mail-attachment-added') {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
      uploadToast.complete()
      onUpload?.()
    }
  }, [uploadToast, lastMessage])

  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const uploadToast = message.upload(file.name)
          setUploadToast(uploadToast)
          uploadToast.show()
          const mailWithUrl = await getThreadMail({
            mailId: mail.id,
            filename: file.name,
          })
          await uploadDocument(mailWithUrl.uploadUrl, file, (progressEvent) => {
            uploadToast.progress(progressEvent.loaded / progressEvent.total)
          })
          uploadToast.processing()
          return true
        })
      )
    },
    onError: handleErrorResponse,
  })
}

export { useAddMailAttachment, useRemoveMailAttachment, useUploadAttachment }
