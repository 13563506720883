import { last } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { useThread } from 'admin/hooks/use-threads'
import { Mail } from 'admin/pages/Thread/Mail/Mail'
import { ThreadForm } from 'admin/pages/Thread/ThreadForm'
import { ThreadProvider } from 'admin/pages/Thread/ThreadProvider'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { IMail } from 'types'

interface Props {
  threadId: string
}

const DockThreadTab = ({ threadId }: Props) => {
  const { closeTab, setTabTitle } = useDockContext()
  const { data: thread } = useThread({ id: threadId })
  const [mail, setMail] = useState<IMail>()

  const mails = useMemo(
    () => thread?.mails.filter(({ isDraft }) => !isDraft) || [],
    [thread]
  )
  const lastMailId = last(thread?.mails?.filter(({ isDraft }) => !isDraft))?.id
  const isNewThread = thread?.isDraft && thread.mails.length === 1

  useEffect(() => {
    const draftMail = thread?.mails?.find(({ isDraft }) => isDraft)
    setMail(draftMail)
  }, [mails])

  useEffect(() => {
    if (mail) {
      setTabTitle(
        threadId,
        <Flex gap={4} alignItems="center">
          {mails.length > 0 && (
            <Button variant="ghost" icon onClick={() => setMail(undefined)}>
              <Icon className="text-grey-600" name={IconName.arrowLeftLong} />
            </Button>
          )}
          {mail.subject || 'New Message'}
        </Flex>
      )
    } else if (mails.length > 0) {
      setTabTitle(threadId, mails?.[0]?.subject || 'New Message')
    }
  }, [mails, mail, mails])

  return thread ? (
    <ThreadProvider thread={thread}>
      {mail ? (
        <ThreadForm
          className="border-0 shadow-none h-full -mx-6 flex flex-col"
          dockMode
          thread={thread}
          mail={mail}
          onDelete={() => closeTab(threadId)}
        />
      ) : (
        <div>
          {mails.map((mail, index) => (
            <Mail
              key={mail.id}
              mailId={mail.id}
              isLastMail={mail.id === lastMailId}
              showActions={index === mails.length - 1 && mail.id === lastMailId}
              className={isNewThread ? '!border-b-0 !pl-0' : ''}
              onReply={(mail) => {
                setMail(mail)
              }}
              onDelete={() => {
                if (thread.mails.length === 1) {
                  closeTab(threadId)
                }
              }}
            />
          ))}
        </div>
      )}
    </ThreadProvider>
  ) : (
    <PageLoader />
  )
}

export { DockThreadTab }
