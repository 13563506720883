import { clsx } from 'clsx'
import { DragEvent, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useUpdateDocument,
  useDeleteDocument,
  useRequestDocument,
} from 'admin/hooks/use-document'
import {
  useAddLoanDocument,
  useAddLoanDocuments,
} from 'admin/hooks/use-loan-documents'
import { useLoanType } from 'admin/hooks/use-loan-type'
import styles from 'admin/pages/Loan/styles.module.scss'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalEditDocument } from 'components/Modal/EditDocument'
import { Panel } from 'components/Panel'
import TableDocuments from 'components/TableDocuments/TableDocuments'
import { TextLink } from 'components/TextLink'
import {
  useDownloadDocument,
  useUploadDocument,
  useDownloadLoanDocuments,
} from 'hooks/use-document'
import { useLoanDocuments } from 'hooks/use-loan-documents'
import { useSession } from 'hooks/use-session'
import { LoanDocument, Loan } from 'types'
import { openBrowseFile } from 'utils/file'
import { getLoanDocumentSections } from 'utils/loan-document-sections'
import { GenerateDocument } from './GenerateDocument'

interface Props {
  loan: Loan
}

function TabDocuments({ loan }: Props) {
  const { Can, ability } = useSession()
  const navigate = useNavigate()
  const { isOrigination } = useLoanType()
  const [dragActiveSectionName, setDragActiveSectionName] = useState<
    string | null
  >(null)
  const [addingDocument, setAddingDocument] =
    useState<Partial<LoanDocument> | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { data: documents, isPending } = useLoanDocuments({ id: loan.id })
  const { mutate: updateDocument, isPending: isUpdating } = useUpdateDocument()
  const { mutate: addDocument, isPending: isAdding } = useAddLoanDocument({
    id: loan.id,
  })
  const { mutate: addDocuments } = useAddLoanDocuments({
    id: loan.id,
  })
  const { mutate: deleteDocument } = useDeleteDocument({ loanId: loan.id })
  const { mutate: downloadDocument } = useDownloadDocument()
  const { mutate: uploadDocument } = useUploadDocument()
  const { mutate: requestDocument } = useRequestDocument()
  const handleOpen = (id: string) => {
    navigate(`${location.pathname}#${id}`, { replace: true })
    navigate(
      pathTo(
        isOrigination ? 'loanDocument' : 'servicingLoanDocument',
        loan.id,
        id
      )
    )
  }
  const handleRequest = (id: string) => requestDocument(id)
  const handleEdit = (id: string) => setEditingId(id)
  const handleDownload = (id: string) => downloadDocument(id)
  const handleUpload = (id: string) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument({ id, name: doc?.name, file: files[0] })
      },
    })
  }
  const handleDropRow = (id: string, files: FileList) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    uploadDocument({ id, name: doc?.name, file: files[0] })
  }
  const handleDropNew = (
    {
      section,
      borrowerId,
      guarantorId,
    }: { section: string; borrowerId?: string; guarantorId?: string },
    files: FileList
  ) => {
    setDragActiveSectionName(null)
    addDocuments({ section, borrowerId, guarantorId, files })
  }
  const handleDelete = (id: string) => {
    deleteDocument(id)
  }
  const handleDrag = (sectionName: string, e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActiveSectionName(sectionName)
    }
  }

  const canReadDocuments = ability.can('read', 'loan-documents')

  const sections = useMemo(
    () => getLoanDocumentSections({ loan, documents }),
    [loan, documents]
  )

  return isPending ? (
    <PageLoader />
  ) : (
    <div className={styles.tabContent}>
      <Flex gap={8} className={styles.tabButton}>
        <Can do="read" on="loan-documents">
          <Button
            variant="secondary"
            className="hidden md:block"
            onClick={() => useDownloadLoanDocuments({ loanId: loan.id })}
          >
            Download All
          </Button>
        </Can>
        <Can do="read" on="generate-documents">
          <GenerateDocument loan={loan} onGenerate={handleOpen} />
        </Can>
      </Flex>
      <Grid gap={16}>
        <Grid.Item sm={12} className={styles.panels}>
          {sections.map(
            ({ name, section, borrowerId, guarantorId, documents }) => (
              <Panel key={name} title={name}>
                <TableDocuments
                  disabled={loan.lock}
                  data={documents}
                  onOpen={canReadDocuments ? handleOpen : undefined}
                  onEdit={handleEdit}
                  onUpload={handleUpload}
                  onFileDrop={handleDropRow}
                  onDownload={canReadDocuments ? handleDownload : undefined}
                  onDelete={handleDelete}
                  onRequest={handleRequest}
                />
                {!loan.lock && (
                  <div
                    className={clsx([
                      styles.addDocument,
                      {
                        [styles.addDocumentHover]:
                          dragActiveSectionName === name,
                      },
                    ])}
                    onDragEnter={(e) => handleDrag(name, e)}
                  >
                    <DragDropFile
                      className={styles.addDocumentDropArea}
                      onLeave={() => setDragActiveSectionName(null)}
                      onDrop={(files) =>
                        handleDropNew(
                          {
                            section,
                            borrowerId,
                            guarantorId,
                          },
                          files
                        )
                      }
                    >
                      Drag and drop to upload files
                    </DragDropFile>
                    <TextLink
                      onClick={() =>
                        setAddingDocument({
                          section,
                          borrowerId,
                          guarantorId,
                        })
                      }
                    >
                      <Icon name={IconName.plus} size="sm" /> Create new
                      document
                    </TextLink>
                  </div>
                )}
              </Panel>
            )
          )}
        </Grid.Item>
      </Grid>
      {(!!addingDocument || !!editingId) && (
        <ModalEditDocument
          saving={isAdding || isUpdating}
          name={(documents || []).find(({ id }) => id === editingId)?.name}
          onSave={({ name }) => {
            if (editingId) {
              updateDocument(
                { id: editingId, name },
                { onSuccess: () => setEditingId(null) }
              )
            } else {
              addDocument(
                { name, ...addingDocument },
                { onSuccess: () => setAddingDocument(null) }
              )
            }
          }}
          onCancel={() => {
            setAddingDocument(null)
            setEditingId(null)
          }}
        />
      )}
    </div>
  )
}

export { TabDocuments }
