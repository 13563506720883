import { client as webauthnClient } from '@passwordless-id/webauthn'
import { Formik } from 'formik'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate, NavLink, useSearchParams } from 'react-router-dom'
import { AppCustomization } from 'components/AppCustomization'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field } from 'components/Form'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import styles from 'pages/Session/styles.module.scss'
import { Credentials, getRegistrationChallenge } from 'services/api/session'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import { AuthIntegrations } from './AuthIntegrations'
import { Errors } from './Errors'
import { NoClientError } from './NoClientError'
import { OpenIdError } from './OpenIdError'
import { SentInviteError } from './SentInviteError'

interface Props {
  admin?: boolean
  pathTo: any
}

const SignInSchema = createScheme({
  email: mergeRules(email, required),
})

const initialValues: Omit<Credentials, 'subdomain' | 'isAdmin'> = {
  email: '',
  password: '',
}

const APP_API_URL = import.meta.env.VITE_APP_API_URL.startsWith('http')
  ? import.meta.env.VITE_APP_API_URL
  : `${window.location.origin}${import.meta.env.VITE_APP_API_URL}`

function SignIn({ pathTo, admin }: Props) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data: client, isPending: isLoading } = useClient()
  const [showPassword, setShowPassword] = useState(false)
  const { signIn, isSigningIn, clearErrors, invalidate, errors } = useSession()

  const show2FA = useMemo(() => client?.settings?.mfa === true, [client])
  const isOpenId = useMemo(
    () => !admin && client?.settings?.openid === true,
    [client]
  )
  const openIDRedirectUrl = useMemo(
    () => new URL(`${APP_API_URL}/login/sso/${client?.id}`),
    [client]
  )

  const handleSubmitPassword = useCallback(
    ({ email, password }: Omit<Credentials, 'subdomain' | 'isAdmin'>) => {
      signIn({
        email,
        password,
        subdomain: subdomain,
        isAdmin: !!admin,
      })
    },
    [subdomain, admin]
  )
  const handleContinue = useCallback(
    async (
      { email }: Omit<Credentials, 'subdomain' | 'isAdmin'>,
      { setFieldValue }
    ) => {
      try {
        const { challenge, numCredentials } = await getRegistrationChallenge({
          email,
          subdomain,
        })
        if (numCredentials > 0) {
          const authentication = await webauthnClient.authenticate({
            challenge,
          })

          return signIn({
            email,
            isAdmin: !!admin,
            authentication,
            subdomain,
          })
        }
      } catch (error) {
        console.error(error)
      }
      setFieldValue('email', email)
      setShowPassword(true)
    },
    [subdomain, admin]
  )

  useEffect(() => {
    const jwt = searchParams?.get('jwt')
    const redirect = searchParams?.get('redirect')
    const openIdCode = searchParams?.get('code')

    if (isOpenId && !openIdCode) {
      // Get the redirect url from the server
      window.location.href = openIDRedirectUrl.href
    }

    // login through auth integration
    if (jwt) {
      // change status from "pending.refresh" to "invalid"
      invalidate()
      signIn({
        isAdmin: !!admin,
        jwt,
        subdomain,
      })
      searchParams.delete('jwt')
      searchParams.delete('redirect')
    }

    // login through openID
    if (isOpenId && openIdCode) {
      // change status from "pending.refresh" to "invalid"
      invalidate()
      // Submit the openID code to the server
      signIn({
        isAdmin: !!admin,
        url: window.location.href,
        subdomain,
      })
    }

    if (jwt || (isOpenId && openIdCode)) {
      const redirectUrl = redirect
        ? new URL(redirect, window.location.origin)
        : null
      const redirectUrlSearchParams = new URLSearchParams({
        ...Object.fromEntries(redirectUrl?.searchParams || []),
        ...Object.fromEntries(searchParams),
      })
      navigate(
        {
          pathname: redirectUrl?.pathname ?? undefined,
          search: redirectUrlSearchParams.toString(),
        },
        { replace: true }
      )
    }
  }, [searchParams, client, isOpenId, admin])

  const sentInvite = errors?.[0]?.includes('invite')
  const errorMessages = errors?.filter((e) => !e.includes('invite'))

  if (isLoading || isOpenId) {
    if (isOpenId && errors?.length) {
      return (
        <OpenIdError openIDRedirectUrl={openIDRedirectUrl} errors={errors} />
      )
    }
    return <PageLoader />
  }

  if (!client && !isLoading) {
    return <NoClientError />
  }

  if (sentInvite) {
    return <SentInviteError signInUrl={pathTo('signIn')} onBack={clearErrors} />
  }

  return (
    <AppCustomization allowNotAuthorized>
      <div className={styles.container}>
        <div className={styles.formBox}>
          <Logo type="statement" to={pathTo('signIn')} />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={
              showPassword || !show2FA ? handleSubmitPassword : handleContinue
            }
            validateOnChange={false}
          >
            <Form className={styles.form} method="post">
              <Header variant="h1" className={styles.title}>
                Log In
              </Header>
              <Text className={styles.description}>
                Welcome to our secure portal. Please enter your credentials
                below to sign-in and get started.
              </Text>
              <Errors errors={errorMessages} />
              <AuthIntegrations signInUrl={pathTo('signIn')} />
              <Field name="email" label="Email" autoComplete="username" />
              {(showPassword || !show2FA) && (
                <Field
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="password"
                />
              )}
              <Button
                loading={isSigningIn}
                type="submit"
                className="w-full"
                size="large"
              >
                {showPassword || !show2FA ? 'Login' : 'Continue'}
              </Button>
              <div className={styles.divider} />
              <Flex gap={16}>
                {client?.settings?.hideInvestors && !admin && (
                  <NavLink to={pathTo('createAccount')} className="link">
                    Create Account
                  </NavLink>
                )}
                <NavLink to={pathTo('forgotPassword')} className="link">
                  Forgot password?
                </NavLink>
              </Flex>
            </Form>
          </Formik>
          <div />
        </div>
        {admin && <div className={styles.background}></div>}
      </div>
    </AppCustomization>
  )
}

export { SignIn }
