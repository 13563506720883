import { without } from 'lodash'
import { Fragment, useCallback, useState } from 'react'
import { SectionHeader } from 'admin/pages/Thread/ThreadForm/ModalAttachDocs/SectionHeader'
import { PageLoader } from 'components/LoaderOverlay'
import { useLoanDocuments } from 'hooks/use-loan-documents'
import { useLoan } from 'hooks/use-loans'
import { LoanDocument } from 'types'
import { Section, getLoanDocumentSections } from 'utils/loan-document-sections'
import { ListDocument } from './ListDocument'
import { ListEmpty } from './ListEmpty'
import { Item } from './ModalAttachDocs'

interface Props {
  currentItem: Item
  selectedDocuments: string[]
  onSelectDocument: (section: Section, document: LoanDocument) => void
}

const ListOfLoanDocuments = ({
  currentItem,
  selectedDocuments,
  onSelectDocument,
}: Props) => {
  const [openSections, setOpenSections] = useState<string[]>([])
  const { data: loan, isPending: isLoanLoading } = useLoan({
    id: currentItem.id,
  })
  const { data: documents, isPending: isLoanDocumentsLoading } =
    useLoanDocuments({
      id: currentItem.id,
    })
  const sections = getLoanDocumentSections({ loan, documents })
  const handleOpenSection = useCallback((sectionName: string) => {
    setOpenSections((openSections) =>
      openSections.includes(sectionName)
        ? without(openSections, sectionName)
        : [...openSections, sectionName]
    )
  }, [])

  return (
    <>
      {isLoanLoading || isLoanDocumentsLoading ? (
        <PageLoader />
      ) : (
        <div>
          {sections.map((section) => (
            <Fragment key={section.name}>
              <SectionHeader
                name={section.name}
                isOpen={openSections.includes(section.name)}
                onClickItem={() => handleOpenSection(section.name)}
              />
              {openSections.includes(section.name) && (
                <div className="border-0 border-b border-solid border-grey-100">
                  {section.documents.length ? (
                    section.documents.map((document) => (
                      <ListDocument
                        key={document.id}
                        document={document}
                        selected={selectedDocuments.includes(document.id)}
                        onSelect={() => {
                          onSelectDocument(section, document)
                        }}
                      />
                    ))
                  ) : (
                    <ListEmpty />
                  )}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      )}
    </>
  )
}

export { ListOfLoanDocuments }
