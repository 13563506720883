import { takeRight } from 'lodash'
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { getNodeText } from 'utils/react-text'
import { DockContext, IDockTab } from './DockContext'

interface Props extends PropsWithChildren {}

const DockProvider = ({ children }: Props) => {
  const [tabs, setTabs] = useState<IDockTab[]>([])
  const [expandedTabId, setExpandedTabId] = useState<string>()

  const handleAddTab = useCallback(
    (tab: IDockTab) => {
      if (!tabs.some(({ id }) => id === tab.id)) {
        setTabs((tabs) => [...tabs, tab])
      }
      if (tab.expandByDefault) {
        setExpandedTabId(tab.id)
      }
    },
    [tabs]
  )

  const handleCloseTab = useCallback((tabId: string) => {
    setExpandedTabId(undefined)
    setTabs((tabs) => tabs.filter((tab) => tab.id !== tabId))
  }, [])

  const handleToggleTab = useCallback((tabId: string) => {
    setExpandedTabId((expandedTabId) =>
      expandedTabId === tabId ? undefined : tabId
    )
  }, [])

  const handleSetTabTitle = useCallback((tabId: string, title: ReactNode) => {
    setTabs((tabs) =>
      tabs.map((tab) => (tab.id === tabId ? { ...tab, title } : tab))
    )
  }, [])

  const expandedTab = useMemo(
    () => tabs.find((tab) => tab.id === expandedTabId),
    [tabs, expandedTabId]
  )
  const visibleTabs = useMemo(
    () =>
      takeRight(
        tabs.filter((tab) => tab.id !== expandedTabId),
        expandedTabId ? 2 : 3
      ),
    [tabs, expandedTabId]
  )

  const value = useMemo(
    () => ({
      tabs,
      addTab: handleAddTab,
      expandTab: setExpandedTabId,
      closeTab: handleCloseTab,
      setTabTitle: handleSetTabTitle,
    }),
    [tabs, setExpandedTabId, handleAddTab]
  )

  return (
    <DockContext.Provider value={value}>
      {children}
      <Flex
        className="fixed bottom-2 right-2 h-[50px]"
        gap={10}
        alignItems="flex-end"
      >
        {visibleTabs.map((tab) => (
          <Flex
            key={tab.id}
            gap={4}
            alignItems="center"
            justifyContent="space-between"
            className="border border-solid border-grey-200 rounded bg-white-100 p-2 w-80 shadow-300 cursor-pointer"
            onClick={() => handleToggleTab(tab.id)}
          >
            <div className="pl-2 py-1 font-bold truncate">
              {getNodeText(tab.title)}
            </div>
            <Flex gap={0}>
              <Button variant="ghost" icon className="text-grey-600">
                <Icon name={IconName.expand} />
              </Button>
              {tab.onMaximizeClick && (
                <Button
                  variant="ghost"
                  icon
                  className="text-grey-600"
                  onClick={() => {
                    tab.onMaximizeClick?.()
                    handleCloseTab(tab.id)
                  }}
                >
                  <Icon name={IconName.maximize} />
                </Button>
              )}
              <Button
                variant="ghost"
                icon
                className="text-grey-600"
                onClick={(e) => {
                  e.stopPropagation()
                  handleCloseTab(tab.id)
                }}
              >
                <Icon name={IconName.close} />
              </Button>
            </Flex>
          </Flex>
        ))}
        {expandedTab && (
          <div className="border border-solid border-grey-200 rounded bg-white-100 pt-4 w-[550px] h-[70vh] min-h-[500px] shadow-300">
            <Flex
              gap={4}
              alignItems="center"
              justifyContent="space-between"
              className="border-0 border-b border-solid border-grey-200 pb-3 mx-6"
            >
              <div className="truncate font-bold text-2xl">
                {expandedTab.title}
              </div>
              <Flex gap={0}>
                <Button
                  variant="ghost"
                  icon
                  className="text-grey-600"
                  onClick={() => handleToggleTab(expandedTab.id)}
                >
                  <Icon name={IconName.collapse} />
                </Button>
                {expandedTab.onMaximizeClick && (
                  <Button
                    variant="ghost"
                    icon
                    className="text-grey-600"
                    onClick={() => {
                      expandedTab.onMaximizeClick?.()
                      handleCloseTab(expandedTab.id)
                    }}
                  >
                    <Icon name={IconName.maximize} />
                  </Button>
                )}
                <Button
                  variant="ghost"
                  icon
                  className="text-grey-600"
                  onClick={() => handleCloseTab(expandedTab.id)}
                >
                  <Icon name={IconName.close} />
                </Button>
              </Flex>
            </Flex>
            <div className="h-[calc(100%-48px)] overflow-y-auto px-6">
              {expandedTab.content}
            </div>
          </div>
        )}
      </Flex>
    </DockContext.Provider>
  )
}

export { DockProvider }
