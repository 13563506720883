import { uniqBy } from 'lodash'
import { useMemo, useCallback } from 'react'
import {
  components as reactSelectComponents,
  MultiValueProps,
  OptionProps,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { usePersons } from 'admin/hooks/use-persons'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import theme from 'styles/theme'
import { IMail, IThread } from 'types'

interface Props {
  people: IThread['people']
  recipients: IMail['to']
  onChange: (recipients: IMail['to']) => void
  className?: string
}

const RecipientOption = (props: OptionProps<any>) => (
  <reactSelectComponents.Option {...props}>
    <Flex alignItems="center" gap={8}>
      <Avatar
        name={props.data.label.charAt(0)}
        exactName
        id={props.data.value}
        color={theme.color.grey700}
        background={theme.color.white100}
        className="border border-solid border-grey-200 w-7 h-7 basis-7 flex-shrink-0"
      />
      <Flex stack gap={4}>
        <div>{props.data.label}</div>
        <div className="text-sm text-grey-700">{props.data.value}</div>
      </Flex>
    </Flex>
  </reactSelectComponents.Option>
)

const MultiValue = (props: MultiValueProps<any>) => {
  const { value, label } = props.data

  return (
    <reactSelectComponents.MultiValue {...props}>
      <Flex gap={10} alignItems="center">
        <Avatar
          name={label.charAt(0)}
          exactName
          id={value}
          color={theme.color.grey700}
          background={theme.color.white100}
          className="border border-solid border-grey-200 w-5 h-5 basis-5 flex-shrink-0"
        />
        <Flex stack gap={2}>
          <div>{label}</div>
        </Flex>
      </Flex>
    </reactSelectComponents.MultiValue>
  )
}

const classNames = {
  control: () => '!border-transparent !shadow-none !min-h-[42px]',
  indicatorsContainer: () => '!hidden',
  multiValue: () =>
    '!bg-white-100 border border-solid border-grey-200 !rounded h-8',
  multiValueLabel: () => 'leading-6 !pl-2 !text-base',
}
const styles = {
  option: (base, { isFocused }) => ({
    ...base,
    backgroundColor: isFocused ? theme.color.grey75 : 'transparent',
  }),
}
const components = { Option: RecipientOption, MultiValue }
const formatCreateLabel = (inputValue: string) => inputValue

const RecipientsSelector = ({
  people,
  recipients,
  onChange,
  className,
}: Props) => {
  const { data } = usePersons({
    filter: {
      email: [['like', '@']],
    },
  })

  const value = useMemo(
    () =>
      recipients.map((recipient) => ({
        label: recipient.name,
        value: recipient.email,
      })),
    [recipients]
  )
  const options = useMemo(() => {
    const peopleOptions = uniqBy(
      [...people, ...(data?.people?.filter((p) => !!p.email) || [])].map(
        (person) => ({
          label: person.name,
          value: person.email,
        })
      ),
      'value'
    )
    value.forEach((value) => {
      if (!peopleOptions.find((person) => person.value === value.value)) {
        peopleOptions.push({ label: value.label, value: value.value })
      }
    })
    return peopleOptions
  }, [people, value, data])

  const handleChange = useCallback(
    (nextRecipients) => {
      onChange(
        nextRecipients.map((recipient) => ({
          name: recipient.label,
          email: recipient.value,
        }))
      )
    },
    [onChange]
  )

  return (
    <CreatableSelect
      className={className}
      classNames={classNames}
      styles={styles}
      options={options}
      value={value}
      isClearable={false}
      onChange={handleChange}
      components={components}
      formatCreateLabel={formatCreateLabel}
      placeholder="Recipients"
      isMulti
    />
  )
}

export { RecipientsSelector }
