import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PanelLinks } from 'components/PanelLinks'
import { useSession } from 'hooks/use-session'

function AccountSettings() {
  const navigate = useNavigate()
  const { user } = useSession()
  const id = user?.admin?.id ?? user?.person?.id
  const name = user?.admin?.name ?? user?.person?.name

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="My Account" />
        <PanelLinks>
          <PanelLinks.Link
            icon={
              <Avatar name={name} id={id} className="h-8 min-w-8 rounded" />
            }
            title="My Info"
            description="Edit your personal account information."
            onClick={() => navigate(pathTo('accountMyAccount'))}
          />
          <PanelLinks.Link
            icon={<Icon name={IconName.envelope} />}
            title="My Email Signatures"
            description="Manage your email signatures."
            onClick={() => navigate(pathTo('accountMailSignatures'))}
          />
          <PanelLinks.Link
            icon={<Icon name={IconName.notification} />}
            title="My Notifications"
            description="Control which notifications you receive from Baseline."
            onClick={() => navigate(pathTo('accountMyNotification'))}
          />
        </PanelLinks>
      </Flex>
    </MainContent>
  )
}

export { AccountSettings }
