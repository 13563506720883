import { UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { useMailSignatures } from 'admin/hooks/use-thread-signature'
import {
  useAddThreadMail,
  useRemoveThread,
  useRemoveThreadMail,
  useUpdateThread,
  useSendThreadMail,
  useUpdateThreadMail,
} from 'admin/hooks/use-threads'
import { IThread, IThreadRequest } from 'types'
import { ThreadContext } from './ThreadContext'

interface Props extends PropsWithChildren {
  thread: IThread
}

const ThreadProvider = ({ thread, children }: Props) => {
  const { data: signatures } = useMailSignatures()
  const { mutate: updateThreadCall, isPending: isUpdating } = useUpdateThread()
  const { mutate: deleteThreadCall, isPending: isDeleting } = useRemoveThread()
  const { mutate: addMailCall } = useAddThreadMail()
  const { mutate: deleteMail } = useRemoveThreadMail()
  const { mutate: updateMail } = useUpdateThreadMail()
  const { mutate: sendMail } = useSendThreadMail()

  const signature = useMemo(
    () => signatures?.find(({ isDefault }) => isDefault),
    [signatures]
  )

  const updateThread = useCallback(
    (
      newThread: Partial<IThreadRequest>,
      options?: UseMutationOptions<
        IThread,
        AxiosError<unknown, any>,
        IThreadRequest,
        unknown
      >
    ) => {
      updateThreadCall({ id: thread.id, ...newThread }, options)
    },
    [updateThreadCall, thread.id]
  )
  const deleteThread = useCallback(() => {
    deleteThreadCall(thread.id)
  }, [deleteThreadCall, thread.id])
  const addMail = useCallback(
    (
      {
        replyTo,
        replyAllTo,
        forwardTo,
      }: {
        replyTo?: string
        replyAllTo?: string
        forwardTo?: string
      },
      options?: any
    ) => {
      addMailCall(
        {
          threadId: thread.id,
          body: signature
            ? `<p></p><p></p><p>--</p>${signature.body}`
            : undefined,
          replyTo,
          replyAllTo,
          forwardTo,
        },
        options
      )
    },
    [addMailCall, thread.id, signature]
  )

  const value = useMemo(
    () => ({
      signature,
      thread,
      isUpdating,
      isDeleting,
      updateThread,
      deleteThread,
      addMail,
      updateMail,
      deleteMail,
      sendMail,
    }),
    [
      signature,
      thread,
      isUpdating,
      updateThread,
      addMail,
      updateMail,
      deleteMail,
      isDeleting,
      sendMail,
    ]
  )

  return (
    <ThreadContext.Provider value={value}>{children}</ThreadContext.Provider>
  )
}

export { ThreadProvider }
