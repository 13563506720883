import { clsx } from 'clsx'
import { last } from 'lodash'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useThread } from 'admin/hooks/use-threads'
import { LinkedContacts } from 'admin/pages/Thread/PanelsForLinking/LinkedContacts'
import { LinkedLoans } from 'admin/pages/Thread/PanelsForLinking/LinkedLoans'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { Header } from './Header/Header'
import { Mail } from './Mail/Mail'
import { ThreadProvider } from './ThreadProvider'

const Thread = () => {
  const navigate = useNavigate()
  const { loanId, threadId } = useParams() as {
    loanId?: string
    threadId: string
  }
  const { data: thread } = useThread({ id: threadId })

  const handleClose = useCallback(
    (replace = false) => {
      if (loanId) {
        const isServicing = window.location.pathname.includes('/servicing')
        navigate(
          pathTo(isServicing ? 'servicingLoanTab' : 'loanTab', loanId, 'mail'),
          { replace }
        )
      } else {
        navigate(pathTo('threads'), { replace })
      }
    },
    [navigate, loanId]
  )

  const lastMailId = last(thread?.mails?.filter(({ isDraft }) => !isDraft))?.id
  const isNewThread = thread?.isDraft && thread.mails.length === 1

  return (
    <MainContent>
      {thread ? (
        <ThreadProvider thread={thread}>
          <Flex stack gap={32}>
            <Header onClose={handleClose} />
            <Flex gap={40}>
              <div
                className={clsx('w-full', !isNewThread && 'overflow-hidden')}
              >
                {!isNewThread && (
                  <div className="mb-8 ml-[68px] text-5xl text-grey-900 font-bold truncate">
                    {thread.mails?.[0]?.subject}
                  </div>
                )}
                {thread.mails.map((mail, index) => (
                  <Mail
                    key={`${mail.id}_${mail.isDraft}`}
                    mailId={mail.id}
                    isLastMail={mail.id === lastMailId}
                    showActions={
                      index === thread.mails.length - 1 &&
                      mail.id === lastMailId
                    }
                    className={isNewThread ? '!border-b-0 !pl-0' : ''}
                    onDelete={() => {
                      if (thread.mails.length === 1) {
                        handleClose(true)
                      }
                    }}
                  />
                ))}
              </div>
              <div className="max-w-xs w-full ">
                <Flex stack gap={32} className="sticky top-20">
                  <LinkedLoans />
                  <LinkedContacts />
                </Flex>
              </div>
            </Flex>
          </Flex>
        </ThreadProvider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Thread }
