import { useState } from 'react'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useAddMailSignature,
  useMailSignatures,
  useUpdateMailSignature,
} from 'admin/hooks/use-thread-signature'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { EmptySignatures } from './EmptySignatures'
import { MailSignatureItem } from './MailSignatureItem'
import { ModalSignature } from './ModalSignature'

function MailSignatures() {
  const { data: signatures, isPending } = useMailSignatures()
  const [editSignatureId, setEditSignatureId] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { mutate: add, isPending: isAdding } = useAddMailSignature()
  const { mutate: update, isPending: isUpdating } = useUpdateMailSignature()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="My Email Signatures"
        />

        {isPending && <PageLoader />}
        {!isPending && !signatures?.length && (
          <EmptySignatures onClick={() => setIsModalVisible(true)} />
        )}
        {!isPending && !!signatures?.length && (
          <Panel
            title="Email Signatures"
            className="max-w-panel"
            actionChildren={
              <Button onClick={() => setIsModalVisible(true)}>
                <Icon name={IconName.addSquare} />
                Add Signature
              </Button>
            }
          >
            <Flex stack gap={16}>
              {signatures.map((signature) => (
                <MailSignatureItem
                  key={signature.id}
                  signature={signature}
                  onEdit={(id) => {
                    setEditSignatureId(id)
                    setIsModalVisible(true)
                  }}
                />
              ))}
            </Flex>
            <Button
              variant="ghost"
              className="mt-4"
              onClick={() => setIsModalVisible(true)}
            >
              <Icon name={IconName.addCircle} className="text-grey-600" />
              New Signature
            </Button>
          </Panel>
        )}
      </Flex>
      {isModalVisible && (
        <ModalSignature
          signature={signatures?.find(({ id }) => id === editSignatureId)}
          onSave={(signature) => {
            if (editSignatureId) {
              update(
                { id: editSignatureId, ...signature },
                {
                  onSuccess: () => {
                    setEditSignatureId(null)
                    setIsModalVisible(false)
                  },
                }
              )
            } else {
              add(signature, {
                onSuccess: () => {
                  setIsModalVisible(false)
                },
              })
            }
            setIsModalVisible(false)
          }}
          saving={isAdding || isUpdating}
          onClose={() => {
            setEditSignatureId(null)
            setIsModalVisible(false)
          }}
        />
      )}
    </MainContent>
  )
}

export { MailSignatures }
