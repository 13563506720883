import { useRemoveMailAttachment } from 'admin/hooks/use-thread-attachments'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'

interface Props {
  mail: IMail
}

const Attachments = ({ mail }: Props) => {
  const { mutate: removeAttachment } = useRemoveMailAttachment()

  return mail.attachments?.length ? (
    <Flex gap={10} flexWrap="wrap" className="px-6 pt-4">
      {mail.attachments?.map((attachment) => (
        <Flex
          key={attachment.id}
          gap={10}
          alignItems="center"
          justifyContent="space-between"
          className="max-w-48 p-1 pr-2.5 pl-1.5 bg-grey-75 text-sm leading-[18px] rounded cursor-pointer hover:bg-grey-100"
        >
          <Flex gap={4} className="truncate">
            <Flex alignItems="center">
              {['PNG', 'JPEG', 'JPG'].includes(
                friendlyMime(attachment.type)
              ) ? (
                <Icon
                  name={IconName.image}
                  size="md"
                  className="text-grey-600"
                />
              ) : (
                <Icon
                  name={IconName.paperClipVertical}
                  size="md"
                  className="text-grey-600"
                />
              )}
            </Flex>
            <div>{friendlyMime(attachment.type)}</div>
            <div className="text-grey-900 font-bold truncate">
              {attachment.name}
            </div>
            <div className="text-grey-600">{friendlySize(attachment.size)}</div>
          </Flex>
          <Flex
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation()
              removeAttachment({ mailId: mail.id, attachmentId: attachment.id })
            }}
          >
            <Icon
              name={IconName.close}
              size="md"
              className="text-grey-600 hover:text-grey-800"
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  ) : null
}

export { Attachments }
