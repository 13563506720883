import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { KEY_THREADS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, IThreadTab, Pagination } from 'types'
import {
  getThreads,
  updateThreads,
  getThread,
  addThread,
  updateThread,
  removeThread,
  addThreadMail,
  removeThreadMail,
  sendThreadMail,
  updateThreadMail,
} from '../services/api/threads'

const useThreads = ({
  search,
  pagination,
  folder,
  loanId,
  filter,
  include,
  exclude,
  checkMail = false,
}: {
  folder?: IThreadTab
  search?: string
  pagination?: Pagination
  loanId?: string
  filter?: Filter
  include?: string[]
  exclude?: string[]
  checkMail?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_THREADS,
      folder,
      search,
      pagination,
      loanId,
      nextFilter,
      include?.join(''),
      exclude?.join(''),
      checkMail,
    ],
    queryFn: () =>
      getThreads({
        folder,
        search,
        page: pagination,
        filter: nextFilter,
        loanId,
        include,
        exclude,
        checkMail,
      }),
    placeholderData: keepPreviousData,
  })
}

const useUpdateThreads = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThreads,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useThread = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_THREADS, id],
    queryFn: () => getThread(id),
  })
}

const useAddThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useAddThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useSendThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThread,
    onSuccess: (thread) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThreadMail,
    onSuccess: (thread) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeThreadMail,
    onSuccess: (thread) => {
      queryClient.setQueryData([KEY_THREADS, thread?.id], thread)
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useThreads,
  useUpdateThreads,
  useThread,
  useAddThread,
  useAddThreadMail,
  useUpdateThread,
  useRemoveThread,
  useRemoveThreadMail,
  useSendThreadMail,
  useUpdateThreadMail,
}
