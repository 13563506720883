import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  onClick: () => void
}

export const EmptySignatures = ({ onClick }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <Flex
            justifyContent="center"
            alignItems="center"
            className={`h-20 w-20 bg-blueberry-25 rounded-[20px]`}
          >
            <Icon
              name={IconName.envelope}
              className="text-blueberry-200 h-12 w-12"
            />
          </Flex>
        }
      />
      <EmptyView.Title title="Add an email signature" className="mt-8" />
      <EmptyView.Description
        description="Click below to add a personal signature to the end of your outbound
        messages"
      />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Add signature</Button>
      </Flex>
    </EmptyView>
  )
}
