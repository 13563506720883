import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useMailSignatures } from 'admin/hooks/use-thread-signature'
import { useAddThread } from 'admin/hooks/use-threads'
import { pathTo } from 'admin/path-to'
import { PageLoader } from 'components/LoaderOverlay'

const ThreadNew = () => {
  const navigate = useNavigate()
  const { loanId } = useParams() as { loanId?: string }
  const { data: signatures, isLoading: isLoadingSignatures } =
    useMailSignatures()
  const { mutate: addThread } = useAddThread()

  const signature = useMemo(
    () => signatures?.find(({ isDefault }) => isDefault),
    [signatures]
  )

  useEffect(() => {
    if (!isLoadingSignatures) {
      addThread(
        {
          loanId,
          body: signature ? `<p></p><p></p><p>--</p>${signature.body}` : '',
        },
        {
          onSuccess: (newThread) => {
            navigate(pathTo('thread', newThread.id))
          },
        }
      )
    }
  }, [loanId, addThread, signatures, isLoadingSignatures])

  return (
    <MainContent>
      <PageLoader />
    </MainContent>
  )
}

export { ThreadNew }
