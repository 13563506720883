import { compact, sortBy, uniqBy } from 'lodash'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PageTop from 'admin/components/PageTop/PageTop'
import { TopMenu } from 'admin/components/TopMenu'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddLoan, useUpdateLoan } from 'admin/hooks/use-loans'
import { useProducts } from 'admin/hooks/use-products'
import { useUsers } from 'admin/hooks/use-users'
import { pathToLoan } from 'admin/path-to'
import { downloadPipelineLoans } from 'admin/services/csv/download-pipeline-loans'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { KanbanBoard as Board } from 'components/KanbanBoard'
import LoansBoardItem from 'components/Loans/LoansBoardItem'
import { ModalAddLoan } from 'components/Modal/AddLoan'
import { Search } from 'components/Search'
import { Empty } from 'components/Table/Empty'
import { useOfFundsOptions } from 'constants/use-of-funds'
import { useLoans } from 'hooks/use-loans'
import { useMobile } from 'hooks/use-mobile'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { EmptyLoans } from './EmptyLoans'

type IAdmin = {
  id: string
  name: string
}

const getLoanAdmins = (loans: Loan[]): IAdmin[] =>
  sortBy(
    uniqBy(compact(loans.map(({ owners }) => owners).flat() || []), 'id').map(
      (item) => ({
        ...item,
        loansCount: loans.filter(
          ({ owners }) => !!owners?.some(({ id }) => id === item.id)
        ).length,
      })
    ),
    'loansCount'
  ).reverse()

function Loans() {
  const navigate = useNavigate()
  const { isAdmin, user } = useSession()
  const { isTablet } = useMobile()
  const [searchParams, setSearchParams] = useSearchParams()
  const [admins, setAdmins] = useState<IAdmin[]>([])
  const [isAddLoanModalVisible, toggleAddLoadModal] = useState(false)
  const [droppingId, setDroppingId] = useState<string | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateLoan()
  const { mutate: addLoan, isPending: isAdding } = useAddLoan()
  const { data: products } = useProducts()
  const { data: owners } = useUsers({
    user,
    clientId: user?.client?.id,
  })

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateClosing',
        type: 'date',
        label: 'Origination Date',
      },
      {
        id: 'amount',
        type: 'currency',
        label: 'Loan Amount',
      },
      {
        id: 'product_id',
        type: 'select',
        label: 'Product',
        options: products?.products.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'admin_id',
        type: 'select',
        label: 'Owners',
        options: owners?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'use_of_funds',
        type: 'select',
        label: 'Use of Funds',
        options: useOfFundsOptions,
      },
    ],
    [products, owners]
  )
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const { visibleItems, result, search, isEmpty, setSearch } =
    usePagination<Loan>({
      property: 'loans',
      useData: (params) =>
        useLoans(
          {
            ...params,
            pagination: { page: 0, size: 10_000 },
            filter: {
              status: [
                'lead',
                'processing',
                'underwriting',
                'approved',
                'closed',
              ],
              ...filterValueToTableFilter(filtersValue),
            },
          },
          {
            refetchInterval: 60000,
            enabled: !isDragging,
          }
        ),
    })
  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
    },
    [setSearchParams]
  )

  const handleDrop = (status: Loan['status'], id: string) => {
    setDroppingId(id)
    update({ id, status }, { onSuccess: () => setIsDragging(false) })
  }
  const handleDownload = useCallback(
    () =>
      downloadPipelineLoans(
        {
          status: ['lead', 'processing', 'underwriting', 'approved', 'closed'],
          ...filterValueToTableFilter(filtersValue),
        },
        search
      ),
    [downloadPipelineLoans, filtersValue, search]
  )

  useEffect(() => {
    if (!admins.length) {
      setAdmins(getLoanAdmins(visibleItems))
    }
  }, [visibleItems])

  useEffect(() => {
    if (visibleItems) {
      setDroppingId(null)
    }
  }, [visibleItems])

  const isInitiallyEmpty = isEmpty && !search && !filtersValue.length

  return (
    <MainContent>
      {isInitiallyEmpty ? (
        <Flex stack gap={24}>
          <PageTop
            title="Pipeline"
            className="gap-6"
            hideTopMenu={!isAdmin && isTablet}
          />
          <EmptyLoans onClick={() => toggleAddLoadModal(true)} />
        </Flex>
      ) : (
        <>
          <Helmet>
            <title>Pipeline</title>
          </Helmet>
          <Flex stack gap={24}>
            <Flex
              justifyContent="flex-end"
              className="md:-mx-8 lg:-mx-14 md:-mt-4 md:border-0 md:border-b md:border-solid md:border-grey-100 md:h-12"
            >
              {isAdmin && !isTablet && <TopMenu />}
            </Flex>
            <Flex alignItems="center" flexWrap="wrap" className="mt-2">
              <Header variant="h1">Pipeline</Header>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              {visibleItems.length ? (
                <div className="!text-grey-900 bg-grey-100 rounded-full font-bold leading-4 px-2.5 py-1 normal-nums">
                  {visibleItems.length}{' '}
                  {visibleItems.length === 1 ? 'opportunity' : 'opportunities'}{' '}
                  -{' '}
                  {formatUsd(
                    sumDecimal(visibleItems.map(({ amount }) => amount)),
                    { showZero: true }
                  )}
                </div>
              ) : (
                <div />
              )}
              <Flex gap={8} alignItems="center">
                <Filter
                  config={filterConfig}
                  value={filtersValue}
                  onApply={handleFilterChange}
                />
                <Search search={search} onSearch={setSearch} />
                <Download filename="loans" download={handleDownload} />
                <Button
                  variant="primary"
                  onClick={() => toggleAddLoadModal(true)}
                >
                  Add Loan
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <div className="h-full mt-5 overflow-x-auto overflow-hidden">
            <Board
              loading={isUpdating || result.isFetching}
              onStateChange={({ isDragging }) =>
                isDragging && setIsDragging(isDragging)
              }
              isEmpty={!result.isFetching && result.data?.loans.length === 0}
              empty={
                <Flex justifyContent="center">
                  <Empty hadData={true} />
                </Flex>
              }
            >
              <Board.Column
                id="lead"
                title="Lead"
                loans={visibleItems.filter(
                  ({ id, status }) => status === 'lead' && id !== droppingId
                )}
                onDrop={(id) => handleDrop('lead', id)}
              >
                {visibleItems
                  .filter(
                    ({ id, status }) => status === 'lead' && id !== droppingId
                  )
                  .map((loan) => (
                    <Board.Item key={loan.id} id={loan.id} columnId="lead">
                      <LoansBoardItem loan={loan} />
                    </Board.Item>
                  ))}
              </Board.Column>
              <Board.Column
                id="processing"
                title="Processing"
                loans={visibleItems.filter(
                  ({ id, status }) =>
                    status === 'processing' && id !== droppingId
                )}
                onDrop={(id) => handleDrop('processing', id)}
              >
                {visibleItems
                  .filter(
                    ({ id, status }) =>
                      status === 'processing' && id !== droppingId
                  )
                  .map((loan) => (
                    <Board.Item
                      key={loan.id}
                      id={loan.id}
                      columnId="processing"
                    >
                      <LoansBoardItem loan={loan} />
                    </Board.Item>
                  ))}
              </Board.Column>
              <Board.Column
                id="underwriting"
                title="Underwriting"
                loans={visibleItems.filter(
                  ({ id, status }) =>
                    status === 'underwriting' && id !== droppingId
                )}
                onDrop={(id) => handleDrop('underwriting', id)}
              >
                {visibleItems
                  .filter(
                    ({ id, status }) =>
                      status === 'underwriting' && id !== droppingId
                  )
                  .map((loan) => (
                    <Board.Item
                      key={loan.id}
                      id={loan.id}
                      columnId="underwriting"
                    >
                      <LoansBoardItem loan={loan} />
                    </Board.Item>
                  ))}
              </Board.Column>
              <Board.Column
                id="approved"
                title="Approved"
                loans={visibleItems.filter(
                  ({ id, status }) => status === 'approved' && id !== droppingId
                )}
                onDrop={(id) => handleDrop('approved', id)}
              >
                {visibleItems
                  .filter(
                    ({ id, status }) =>
                      status === 'approved' && id !== droppingId
                  )
                  .map((loan) => (
                    <Board.Item key={loan.id} id={loan.id} columnId="approved">
                      <LoansBoardItem loan={loan} />
                    </Board.Item>
                  ))}
              </Board.Column>
              <Board.Column
                id="closed"
                title="Closed"
                loans={visibleItems.filter(
                  ({ id, status }) => status === 'closed' && id !== droppingId
                )}
                onDrop={(id) => handleDrop('closed', id)}
              >
                {visibleItems
                  .filter(
                    ({ id, status }) => status === 'closed' && id !== droppingId
                  )
                  .map((loan) => (
                    <Board.Item key={loan.id} id={loan.id} columnId="closed">
                      <LoansBoardItem loan={loan} />
                    </Board.Item>
                  ))}
              </Board.Column>
            </Board>
          </div>
        </>
      )}
      {isAddLoanModalVisible && (
        <ModalAddLoan
          include={['product', 'useOfFunds']}
          saving={isAdding}
          onCancel={() => toggleAddLoadModal(false)}
          onSave={(loan) => {
            addLoan(loan, {
              onSuccess: (loan) => {
                toggleAddLoadModal(false)
                navigate(pathToLoan(loan))
              },
            })
          }}
        />
      )}
    </MainContent>
  )
}

export { Loans }
