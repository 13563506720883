import { capitalize } from 'lodash'
import { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Indicator, IndicatorProps } from 'components/Indicator'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { substatusToColor } from 'constants/substatus'
import { useUpdateLoanOwners } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

interface Props {
  loan: Loan
}

function LoansBoardItem({ loan: propLoan }: Props) {
  const navigate = useNavigate()
  const { isAdminManager } = useSession()
  const { mutate: updateOwners } = useUpdateLoanOwners()
  const [loan, setLoan] = useState<Loan>(propLoan)
  const borrower = loan.borrowers.find(({ borrow }) => borrow.primary)
  const { isVisible, options, selectedOwnerIds } = useOwnerSelector({
    selectedOwners: loan?.owners || [],
  })
  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id: loan.id, owners }, { onSuccess: setLoan })
    },
    [loan.id, updateOwners]
  )
  const isReadOnly = loan.lock || !isAdminManager

  useEffect(() => {
    setLoan(propLoan)
  }, [propLoan])

  return (
    <div className="p-4" onClick={() => navigate(pathTo('loan', loan.id))}>
      <Text className="font-bold">{loan.name}</Text>
      {loan.substatus && (
        <Flex alignItems="center" gap={4} className="text-sm mt-1">
          <Indicator
            color={substatusToColor(loan.substatus) as IndicatorProps['color']}
          />
          {loan.substatus}
        </Flex>
      )}

      <Flex
        stack
        gap={4}
        className={`text-grey-700 ${borrower?.name || loan.dateClosing || loan.product?.name ? 'mt-3' : ''}`}
      >
        {borrower?.name && (
          <Flex alignItems="center" gap={6}>
            <Icon name={IconName.borrower} className="w-[13px] h-[13px]" />
            <Text variant="s">{borrower.name}</Text>
          </Flex>
        )}
        {loan.dateClosing && (
          <Flex alignItems="center" gap={6}>
            <Icon name={IconName.calendar} className="w-[13px] h-[13px]" />
            <Text variant="s">{friendlyDate(loan.dateClosing)}</Text>
          </Flex>
        )}
        {loan.product?.name && (
          <Flex alignItems="center" gap={6}>
            <Icon
              name={IconName.productNavigation}
              className="w-[13px] h-[13px]"
            />
            <Text variant="s">{loan.product.name}</Text>
          </Flex>
        )}
      </Flex>

      <Flex className={loan.amount || loan.useOfFunds ? 'mt-3' : ''}>
        {loan.amount || loan.useOfFunds ? (
          <Badge color="gray" className="flex whitespace-pre-wrap">
            {loan.amount && (
              <Text variant="s" className="text-grey-900">
                {formatUsd(loan.amount)}
              </Text>
            )}
            {loan.amount && loan.useOfFunds && ' - '}
            {loan.useOfFunds && (
              <Text variant="s" className="truncate">
                {capitalize(loan.useOfFunds)}
              </Text>
            )}
          </Badge>
        ) : (
          <div />
        )}
      </Flex>
      {isVisible &&
        (!isReadOnly || (!!selectedOwnerIds.length && isReadOnly)) && (
          <div className="mt-1">
            <OwnerSelector
              userOptions={options}
              selectedUsers={selectedOwnerIds}
              onChange={handleOwnersChange}
              readOnly={isReadOnly}
            />
          </div>
        )}
    </div>
  )
}

export default LoansBoardItem
