import clsx from 'clsx'
import { DragEvent, ReactNode } from 'react'
import { message } from '../../utils/message'
import { Header } from '../Header'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface Props {
  className?: string
  children?: ReactNode
  onLeave: () => void
  onDrop: (files: FileList) => void
  accept?: string[]
}

export default function DragDropFile({
  onLeave,
  onDrop,
  children,
  className,
  accept = [
    'jpg',
    'jpeg',
    'png',
    'tif',
    'heic',
    'pdf',
    'xlsx',
    'docx',
    'pptx',
    'xls',
    'msg',
  ],
}: Props) {
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragleave') {
      onLeave()
    }
  }
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (
        Array.from(e.dataTransfer.files).filter(
          ({ name }) =>
            !accept.includes(name.toLowerCase().split('.').pop() || '')
        ).length > 0
      ) {
        message.error("Sorry, we don't support that type of file")
        onLeave()
      } else {
        onDrop(e.dataTransfer.files)
      }
    }
  }

  return (
    <div
      className={clsx(styles.dragDropFile, className)}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {children ? (
        children
      ) : (
        <>
          <Icon
            name={IconName.uploadCloud}
            size="xxxl"
            className={styles.icon}
          />
          <Header className={styles.noPreviewHeader}>
            Drag and drop to upload file
          </Header>
        </>
      )}
    </div>
  )
}
