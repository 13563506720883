import { useMutation } from '@tanstack/react-query'
import { flatten } from 'lodash'
import { useState } from 'react'
import { PanelAddress } from 'admin/components/PanelAddress'
import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateInvestorBanking } from 'admin/hooks/use-banking'
import { InvestorDetails, inviteEmail } from 'admin/services/api/investors'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import PanelEmail from 'components/Panel/PanelEmail'
import PanelEntityInformation from 'components/Panel/PanelEntityInformation'
import { PanelPersonCustom } from 'components/Panel/PanelPersonCustom'
import PanelPersonalInformation from 'components/Panel/PanelPersonalInformation'
import {
  useAddInvestorAddress,
  useUpdateInvestor,
  useUpdateInvestorAddress,
} from 'hooks/use-investor'
import { useSession } from 'hooks/use-session'
import { handleErrorResponse } from 'services/request'
import { Field } from 'types'
import { message } from 'utils/message'
import PanelLinkedAccounts from './PanelLinkedAccounts'
import styles from './styles.module.scss'

interface Props {
  investor: InvestorDetails
  onSecure: (secure: boolean) => void
  fields?: Field[]
}

function TabGeneral({ investor, onSecure, fields }: Props) {
  const { Can, user } = useSession()
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [sentInvite, setSentInvite] = useState(false)
  const { mutate: updateInvestor, isPending: isUpdatingInvestor } =
    useUpdateInvestor()
  const { mutate: addAddress, isPending: addingAddress } =
    useAddInvestorAddress(investor.id)
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdateInvestorAddress(investor.id)

  const inviteInvestor = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      message.success('Invitation sent')
      setSentInvite(true)
    },
  })

  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateInvestorBanking(investor.id)

  const isIndividual = !investor.type || investor.type === 'individual'

  const isOpenId = user?.client?.settings?.openid

  return (
    <div className={styles.tabContent}>
      {isIndividual &&
        !isOpenId &&
        ['draft', 'invited', 'active'].includes(investor.status) && (
          <Button
            className={styles.tabButton}
            onClick={() => inviteInvestor.mutate({ id: investor.id })}
            loading={inviteInvestor.isPending}
            iconLeft={<Icon name={IconName.send} />}
          >
            {investor.status !== 'draft' || sentInvite
              ? 'Re-invite User'
              : 'Invite User'}
          </Button>
        )}

      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          {isIndividual ? (
            <PanelPersonalInformation
              person={investor}
              onSecure={onSecure}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              personType="investor"
            />
          ) : (
            <PanelEntityInformation
              person={investor}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onSecure={onSecure}
            />
          )}
          <PanelPersonCustom
            personRoleType="investor"
            fields={fields}
            person={investor}
          />
          <PanelAddress
            person={investor}
            isLoading={addingAddress || updatingAddress}
            addAddress={addAddress}
            updateAddress={updateAddress}
          />
          <Can do="read" on="banking">
            <PanelEntityBanking
              banking={investor.banking}
              title="Bank Account"
              updateBanking={updateBanking}
              isSaving={savingBanking}
            />
          </Can>
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={investor.id}
            type={isIndividual ? 'individual' : 'entity'}
            emails={investor.emails}
            personType="investor"
            isInvited={['invited', 'active'].includes(investor.status)}
            readonlyEmails={flatten(
              investor.managers?.map((m) => m.emails || [])
            )}
          />

          <PanelLinkedAccounts investor={investor} />
        </Grid.Item>
        {isInfoModalVisible && (
          <ModalPersonInfo
            personType="investor"
            person={investor}
            saving={isUpdatingInvestor}
            onSave={(values) =>
              updateInvestor(
                { ...values, id: investor.id },
                {
                  onSuccess: () => {
                    message.success('Information saved')
                    setIsInfoModalVisible(false)
                  },
                }
              )
            }
            onCancel={() => setIsInfoModalVisible(false)}
          />
        )}
      </Grid>
    </div>
  )
}

export default TabGeneral
