import clsx from 'clsx'
import { isSameDay } from 'date-fns'
import DOMPurify from 'dompurify'
import { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { ThreadForm } from 'admin/pages/Thread/ThreadForm'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'
import { friendlyDateTime, formatRelativeDate } from 'utils/date'
import { MailActions } from './MailActions'
import { MailAttachments } from './MailAttachments'

interface Props {
  mailId: string
  isLastMail: boolean
  showActions: boolean
  className?: string
  onReply?: (mail: IMail) => void
  onDelete?: (mail: IMail) => void
}

const Mail = memo(
  ({
    mailId,
    isLastMail,
    showActions,
    className,
    onReply,
    onDelete,
  }: Props) => {
    const bodyRef = useRef<HTMLIFrameElement>(null)
    const { thread } = useThreadContext()
    const mail = useMemo(
      () => thread.mails.find(({ id }) => id === mailId)!,
      [thread.mails, mailId]
    )
    const [isExpanded, setIsExpanded] = useState<boolean>(isLastMail)
    const { addMail } = useThreadContext()

    const handleReply = useCallback(
      (mode: 'reply' | 'reply-all' | 'forward') => {
        if (mode === 'reply') {
          addMail({ replyTo: mail.id }, { onSuccess: onReply })
        }
        if (mode === 'reply-all') {
          addMail({ replyAllTo: mail.id }, { onSuccess: onReply })
        }
        if (mode === 'forward') {
          addMail({ forwardTo: mail.id }, { onSuccess: onReply })
        }
      },
      [addMail, mail.id]
    )

    useEffect(() => {
      const setHeight = () => {
        if (bodyRef.current) {
          const contentHeight =
            bodyRef.current.contentDocument?.body.scrollHeight ?? 0
          if (contentHeight > 0) {
            bodyRef.current.style.height = `${contentHeight}px`
          }
        }
      }

      if (isExpanded && bodyRef.current) {
        bodyRef.current.contentDocument!.open()
        bodyRef.current.contentDocument!.write(DOMPurify.sanitize(mail.body))
        bodyRef.current.contentDocument!.close()

        bodyRef.current.contentDocument!.body.style.fontFamily =
          'Arial, sans-serif'
        bodyRef.current.contentDocument!.body.style.fontSize = '13px'
        bodyRef.current.contentDocument!.body.style.color = '#343332'
        bodyRef.current.contentDocument!.body.style.overflowY = 'hidden'

        bodyRef.current.addEventListener('load', setHeight)
        return () => bodyRef.current?.removeEventListener('load', setHeight)
      }
    }, [isExpanded, mail.body])

    return mail.isDraft ? (
      <div
        className={clsx(
          'pt-6 pr-4 pb-5 pl-[68px] border-0 border-b border-solid border-grey-200',
          className
        )}
      >
        <ThreadForm thread={thread} mail={mail} onDelete={onDelete} />
      </div>
    ) : (
      <>
        <Flex
          stack
          gap={20}
          onClick={() => !isLastMail && setIsExpanded((state) => !state)}
          className={clsx(
            'py-5 px-4 border-0 border-b border-solid border-grey-200',
            !isLastMail && 'hover:cursor-pointer',
            className
          )}
        >
          <Flex gap={20} justifyContent="space-between">
            <Flex gap={20} className="truncate">
              <div className="rounded-full">
                <Avatar
                  id={
                    mail.from?.adminId ?? mail.from?.personId ?? mail.from.name
                  }
                  name={mail.from.name}
                  className="h-8 w-8 text-base cursor-pointer"
                />
              </div>
              <Flex stack gap={4} className="truncate">
                <div className="text-lg text-grey-900 font-bold leading-5">
                  {mail.from.name}
                </div>
                <div className="text-grey-700 leading-[18px]">
                  {isExpanded ? (
                    <Flex stack gap={4}>
                      <div className="truncate">
                        To: {mail.to.map((mailTo) => mailTo.email).join(', ')}
                      </div>
                      {mail.cc.length > 0 && (
                        <div className="truncate">
                          Cc: {mail.cc.map((mailTo) => mailTo.email).join(', ')}
                        </div>
                      )}
                    </Flex>
                  ) : (
                    <div className="truncate">{mail.subject}</div>
                  )}
                </div>
              </Flex>
            </Flex>
            <Flex gap={20} alignItems="center" className="h-8">
              <div className="text-sm text-grey-700 leading-[18px] whitespace-nowrap">
                {isSameDay(mail.date, Date.now())
                  ? friendlyDateTime(mail.date, 'h:mm a')
                  : friendlyDateTime(mail.date, 'MMM d')}{' '}
                ({formatRelativeDate(mail.date)})
              </div>
              {isExpanded && (
                <Flex gap={10} alignItems="center">
                  <Button
                    variant="ghost"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleReply('reply')
                    }}
                    className="w-8"
                  >
                    <Icon
                      name={IconName.arrowReply}
                      size="md"
                      className="text-grey-600"
                    />
                  </Button>
                  <MailActions
                    onReplyAll={() => handleReply('reply-all')}
                    onForward={() => handleReply('forward')}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
          {isExpanded && (
            <Flex stack gap={0} className="mb-3 ml-[52px]">
              <iframe ref={bodyRef} className="border-0" />
              <MailAttachments attachments={mail.attachments} />
            </Flex>
          )}
        </Flex>

        {showActions && (
          <Flex gap={8} className="ml-[52px] py-5 px-4">
            {[
              {
                id: 'reply',
                buttonName: 'Reply',
                iconName: IconName.arrowReply,
              },
              {
                id: 'reply-all',
                buttonName: 'Reply all',
                iconName: IconName.arrowReplyAll,
              },
              {
                id: 'forward',
                buttonName: 'Forward',
                iconName: IconName.arrowForward,
              },
            ].map((btn) => (
              <Flex
                key={btn.id}
                gap={8}
                alignItems="center"
                onClick={() =>
                  handleReply(btn.id as 'reply' | 'reply-all' | 'forward')
                }
                className="py-2 px-3 rounded text-grey-900 font-bold cursor-pointer hover:bg-grey-100"
              >
                <Icon name={btn.iconName} className="text-grey-600" />
                <div className="leading-5 text-grey-900">{btn.buttonName}</div>
              </Flex>
            ))}
          </Flex>
        )}
      </>
    )
  }
)

Mail.displayName = 'Mail'

export { Mail }
