import { request } from 'services/request'
import { IMail, IThread } from 'types'

const addMailAttachment = async ({
  mailId,
  documentIds,
}: {
  mailId: string
  documentIds: string[]
}): Promise<{ mail: IMail; thread: IThread }> => {
  const { data } = await request.put(`/mail/${mailId}/document`, {
    ids: documentIds,
  })
  return data
}

const removeMailAttachment = async ({
  mailId,
  attachmentId,
}: {
  mailId: string
  attachmentId: string
}) => {
  const { data } = await request.delete(
    `/mail/${mailId}/attachment/${attachmentId}`
  )
  return data
}

export { addMailAttachment, removeMailAttachment }
