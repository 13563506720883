import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import { clsx } from 'clsx'
import { debounce } from 'lodash'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { IMail, IThread } from 'types'
import { RecipientsSelector } from './RecipientsSelector'

interface Props {
  thread: IThread
  mail: IMail
  dockMode?: boolean
  onChange: (payload: Partial<IMail>) => void
}

const responseTypes = [
  { name: 'Reply', value: 'reply', icon: IconName.arrowReply },
  { name: 'Reply all', value: 'reply-all', icon: IconName.arrowReplyAll },
  { name: 'Forward', value: 'forward', icon: IconName.arrowForward },
] as {
  name: string
  value: 'reply' | 'reply-all' | 'forward'
  icon: IconName
}[]

const Addressees = ({ thread, dockMode, mail, onChange }: Props) => {
  const [open, setOpen] = useState(false)
  const [subject, setSubject] = useState(mail.subject)
  const [to, setTo] = useState(mail.to)
  const [cc, setCc] = useState(mail.cc)
  const isResponse = useMemo(
    () => !!mail.replyTo || !!mail.replyAllTo || !!mail.forwardTo,
    [mail]
  )
  const [isSubjectVisible, setIsSubjectVisible] = useState(!isResponse)
  const [isCcVisible, setIsCcVisible] = useState(mail.cc.length > 0)
  const onChangeDebounced = useMemo(() => debounce(onChange, 300), [onChange])

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const handleModeChange = useCallback(
    (mode: 'reply' | 'reply-all' | 'forward') => {
      const replyMailId = mail.replyTo || mail.replyAllTo || mail.forwardTo
      if (mode === 'reply') {
        onChange({ id: mail.id, replyTo: replyMailId })
      }
      if (mode === 'reply-all') {
        onChange({ id: mail.id, replyAllTo: replyMailId })
      }
      if (mode === 'forward') {
        onChange({ id: mail.id, forwardTo: replyMailId })
      }
    },
    [mail.id, onChange]
  )

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  const responseType = useMemo(() => {
    if (mail.replyTo) {
      return 'reply'
    }
    if (mail.replyAllTo) {
      return 'reply-all'
    }
    if (mail.forwardTo) {
      return 'forward'
    }
    return undefined
  }, [mail])

  useEffect(() => {
    onChangeDebounced({
      id: mail.id,
      subject: subject,
      to: to,
      cc: cc,
    })
  }, [onChangeDebounced, mail.id, subject, to, cc])

  useEffect(() => {
    setCc(mail.cc)
    if (mail.cc.length) {
      setIsCcVisible(true)
    }
  }, [mail.cc])

  return (
    <>
      {isResponse ? (
        <Flex stack gap={0} className={clsx(dockMode && 'mx-6')}>
          <Flex
            gap={10}
            alignItems="center"
            className={clsx(
              'py-1.5 border-0 border-b border-solid border-grey-200 text-grey-800',
              !dockMode && 'px-6'
            )}
          >
            <Flex
              gap={6}
              className="h-8 px-2 rounded cursor-pointer hover:bg-grey-100"
              {...getReferenceProps({
                ref: refs.setReference,
                onClick(e) {
                  e.stopPropagation()
                },
              })}
            >
              <Icon
                name={
                  responseTypes.find(({ value }) => value === responseType)!
                    .icon
                }
                size="md"
                className="m-auto text-grey-700"
              />
              <Icon
                name={IconName.arrowDownFilled}
                size="md"
                className="m-auto text-grey-700"
              />
            </Flex>
            <Flex gap={10} alignItems="center" flexGrow={1}>
              <RecipientsSelector
                className="w-full"
                people={thread.people}
                recipients={to}
                onChange={setTo}
              />
            </Flex>
            {!isCcVisible && (
              <Button
                variant="ghost"
                className="text-grey-600"
                onClick={() => setIsCcVisible(true)}
              >
                Cc
              </Button>
            )}
            {open && (
              <FloatingPortal>
                <Flex
                  stack
                  gap={0}
                  className="min-w-[170px] p-1 bg-white-100 shadow-300 rounded py-1 border-solid border-[1px] border-grey-200"
                  onClick={(e) => e.stopPropagation()}
                  {...getFloatingProps({
                    ref: refs.setFloating,
                    style: {
                      position: strategy,
                      top: y ?? 0,
                      left: x ?? 0,
                      zIndex: 5,
                    },
                  })}
                >
                  {responseTypes.map(({ name, value, icon }) => (
                    <Flex
                      key={value}
                      gap={8}
                      alignItems="center"
                      className="py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
                      {...getItemProps({
                        onClick: () => {
                          setOpen(false)
                          handleModeChange(value)
                        },
                      })}
                    >
                      <Icon name={icon} size="md" className="text-grey-600" />
                      <div className="leading-5 text-grey-900">{name}</div>
                    </Flex>
                  ))}
                  {!isSubjectVisible && (
                    <div
                      key="show-subject"
                      className="border-0 border-t border-solid border-grey-200 mt-1 pt-1"
                      {...getItemProps({
                        onClick: () => {
                          setOpen(false)
                          setIsSubjectVisible(true)
                        },
                      })}
                    >
                      <Flex
                        alignItems="center"
                        className="py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
                      >
                        <div className="leading-5 text-grey-900">
                          Edit Subject
                        </div>
                      </Flex>
                    </div>
                  )}
                </Flex>
              </FloatingPortal>
            )}
          </Flex>
          {isCcVisible && (
            <Flex
              alignItems="center"
              className={clsx(
                'py-1 border-0 border-b border-solid border-grey-200 text-grey-800',
                dockMode && 'px-2',
                !dockMode && 'px-8'
              )}
            >
              <div className="w-8">Cc:</div>
              <RecipientsSelector
                className="w-full"
                people={thread.people}
                recipients={cc}
                onChange={setCc}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <>
          <div className="mx-6">
            <Flex
              alignItems="center"
              className="py-3 border-0 border-b border-solid border-grey-200 text-grey-800"
            >
              <div className="w-10">From:</div>
              <Flex
                alignItems="center"
                className="p-1.5 rounded border border-solid border-grey-200"
              >
                <Avatar
                  id={mail.from.email}
                  name={mail.from.name || mail.from.email}
                  className="!h-5 !w-5 flex content-center !basis-5 !text-[9px]"
                />
                <div className="text-sm font-bold text-grey-800">
                  {mail.from.name || mail.from.email}
                </div>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              className="py-1.5 border-0 border-b border-solid border-grey-200 text-grey-800"
            >
              <div className="w-8">To:</div>
              <RecipientsSelector
                className="w-full"
                people={thread.people}
                recipients={to}
                onChange={setTo}
              />
              {!isCcVisible && (
                <Button
                  variant="ghost"
                  className="text-grey-600"
                  onClick={() => setIsCcVisible(true)}
                >
                  Cc
                </Button>
              )}
            </Flex>
            {isCcVisible && (
              <Flex
                alignItems="center"
                className="py-1 border-0 border-b border-solid border-grey-200 text-grey-800"
              >
                <div className="w-8">Cc:</div>
                <RecipientsSelector
                  className="w-full"
                  people={thread.people}
                  recipients={cc}
                  onChange={setCc}
                />
              </Flex>
            )}
          </div>
        </>
      )}
      {isSubjectVisible && (
        <Flex alignItems="center" className="pt-3 mx-6 text-grey-800">
          <Input
            className="border-0 font-bold text-lg"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Flex>
      )}
    </>
  )
}

export { Addressees }
