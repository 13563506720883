import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { compact, isNil } from 'lodash'
import { useMemo } from 'react'
import { DocumentStatusBadge, Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import { LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { friendlyMime, friendlySize } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  data?: LoanDocument[]
  disabled?: boolean
  isTitleBold?: boolean
  hideEllipsesMenu?: boolean
  minWidth?: number
  loading?: boolean
  hideStatus?: boolean
  showDownload?: boolean
  onSign?: (id: string) => void
  onOpen?: (id: string) => void
  onEdit?: (id: string) => void
  onRequest?: (id: string) => void
  onDownload?: (id: string) => void
  onUpload?: (id: string) => void
  onFileDrop?: (id: string, files: FileList) => void
  onView?: (id: string) => void
  onDelete?: (id: string) => void
}

const documentStatesWhenCanUpload = [
  'requested',
  'rejected',
  'in_review',
  'pending',
]

function TableDocuments({
  data = [],
  disabled,
  isTitleBold,
  minWidth = 0,
  hideStatus,
  hideEllipsesMenu,
  showDownload,
  loading,
  onOpen,
  onEdit,
  onSign,
  onDownload,
  onUpload,
  onFileDrop,
  onView,
  onDelete,
  onRequest,
}: Props) {
  const { user } = useSession()
  const { isTablet } = useMobile()

  const columns: ColumnDef<LoanDocument>[] = useMemo(
    () =>
      compact([
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ row, getValue }) => {
            const name = getValue() as string
            return onView && row.original.isShared ? (
              <TextLink onClick={() => onView(row.original.id)}>
                {name}
              </TextLink>
            ) : (
              <>
                {isTitleBold ? <b>{name}</b> : name}
                {row.original.isShared && !onView ? (
                  <>
                    &nbsp;&nbsp;
                    <Badge color="green">
                      <Flex alignItems="center" gap={8}>
                        <Icon
                          className={styles.sharedIcon}
                          name={IconName.share}
                          size="sm"
                        />
                        <div className={styles.badge}>Shared</div>
                      </Flex>
                    </Badge>
                  </>
                ) : null}
              </>
            )
          },
        },
        {
          header: 'Type',
          accessorKey: 'type',
          size: 100,
          cell: (cell) => {
            const meme = (cell.getValue() as string) || ''
            return friendlyMime(meme)
          },
        },
        {
          header: 'Size',
          accessorKey: 'size',
          size: 100,
          cell: (cell) => {
            const size = cell.getValue() as number
            return friendlySize(size)
          },
        },
        {
          header: 'Uploaded Date',
          accessorKey: 'uploadedDate',
          size: 160,
          cell: (cell) => {
            const date = cell.getValue() as string
            return friendlyDate(date)
          },
        },
        hideStatus
          ? null
          : {
              header: 'Status',
              accessorKey: 'status',
              size: 120,
              cell: ({ row, getValue }) => {
                const isAdmin = !!user?.admin
                const isWaitingForSign =
                  [user?.actor?.id.toString(), user?.borrower?.id.toString()]
                    .filter(Boolean)
                    .includes(
                      row.original.esignature?.currentSigner?.toString()
                    ) && !row.original.esignature?.currentSigned
                const status = getValue() as LoanDocument['status']

                if (isWaitingForSign && !isAdmin) {
                  return null
                }
                if (row.original.esignatureSlug && !isAdmin) {
                  return <DocumentStatusBadge status="in_review" />
                }
                return (
                  <DocumentStatusBadge
                    status={status}
                    isSignature={!!row.original.esignatureSlug}
                  />
                )
              },
            },
        {
          id: 'actions',
          header: '',
          size: hideEllipsesMenu ? 140 : 80,
          cell: ({ row }) => {
            const id = row.original.id
            const isWaitingForSign =
              [user?.actor?.id.toString(), user?.borrower?.id.toString()]
                .filter(Boolean)
                .includes(row.original.esignature?.currentSigner?.toString()) &&
              !row.original.esignature?.currentSigned &&
              !!onSign
            const isUploadAvailable =
              isNil(row.original.esignature?.status) &&
              documentStatesWhenCanUpload.includes(row.original.status) &&
              !!onUpload

            return disabled ? null : (
              <div className={`${isTablet ? '' : 'justify-content-end flex'}`}>
                {isWaitingForSign && (
                  <TextLink
                    className={clsx(
                      'whitespace-nowrap',
                      hideEllipsesMenu
                        ? styles.iconUploadHideEllipses
                        : styles.iconUpload
                    )}
                    onClick={(e) => {
                      e.stopPropagation()
                      onSign?.(id)
                    }}
                  >
                    <Icon
                      name={IconName.signature}
                      size="md"
                      className="text-grey-600"
                    />
                    Review & Sign
                  </TextLink>
                )}
                {isUploadAvailable && (
                  <TextLink
                    className={
                      hideEllipsesMenu
                        ? styles.iconUploadHideEllipses
                        : styles.iconUpload
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      onUpload(id)
                    }}
                  >
                    <Icon
                      name={IconName.upload}
                      size="md"
                      className="text-grey-600"
                    />
                    {hideEllipsesMenu && 'Upload'}
                  </TextLink>
                )}
                {row.original.isShared &&
                  !documentStatesWhenCanUpload.includes(row.original.status) &&
                  onView && (
                    <TextLink
                      className={
                        hideEllipsesMenu
                          ? styles.iconUploadHideEllipses
                          : styles.iconUpload
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        onView(id)
                      }}
                    >
                      <Icon
                        name={IconName.view}
                        size="md"
                        className="text-grey-600"
                      />
                      {hideEllipsesMenu && 'View'}
                    </TextLink>
                  )}
                {showDownload && onDownload && (
                  <TextLink
                    className={
                      hideEllipsesMenu
                        ? styles.iconUploadHideEllipses
                        : styles.iconUpload
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      onDownload?.(id)
                    }}
                  >
                    <Icon
                      name={IconName.download}
                      size="md"
                      className="text-grey-600"
                    />
                    {hideEllipsesMenu && 'Download'}
                  </TextLink>
                )}
                {hideEllipsesMenu ? null : (
                  <EllipsesActions>
                    {onEdit ? (
                      <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                        <Icon name={IconName.edit} />
                        Edit
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onRequest ? (
                      <EllipsesActions.Item icon onSelect={() => onRequest(id)}>
                        <Icon name={IconName.requestDocument} />
                        Request
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onUpload ? (
                      <EllipsesActions.Item icon onSelect={() => onUpload(id)}>
                        <Icon name={IconName.upload} />
                        Upload
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onDownload ? (
                      <EllipsesActions.Item
                        icon
                        disabled={!row.original.size}
                        onSelect={() => onDownload?.(id)}
                      >
                        <Icon name={IconName.download} />
                        Download
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onDelete ? (
                      <EllipsesActions.Item
                        icon
                        onSelect={() => onDelete(id)}
                        className="text-red-100"
                      >
                        <Icon name={IconName.delete} />
                        Delete
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                  </EllipsesActions>
                )}
              </div>
            )
          },
        },
      ]),
    [
      isTablet,
      hideStatus,
      hideEllipsesMenu,
      onSign,
      onUpload,
      onView,
      onDownload,
      onEdit,
      onRequest,
      onDelete,
    ]
  )
  const handleFileDrop = useMemo(() => {
    return disabled || !onFileDrop
      ? undefined
      : (row: LoanDocument, files: FileList) => {
          onFileDrop(row.id, files)
        }
  }, [disabled, onFileDrop])

  return (
    <Table
      loading={loading}
      minWidth={minWidth}
      className={styles.tableDocuments}
      columns={columns}
      data={data.map((doc) => ({
        ...doc,
        rowClassName: doc.isCustom ? styles.rowCustom : undefined,
      }))}
      onFileDrop={handleFileDrop}
      onClick={onOpen ? (row) => onOpen((row as LoanDocument).id) : undefined}
    />
  )
}

export default TableDocuments
